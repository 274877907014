<script lang="ts">
  export let host: string | undefined = undefined;
  export let port: number | undefined = undefined;
  export let isDefaultPort: boolean = false;
  export let joinLink: string = undefined;
  export let isPrivate: boolean = false;

  const label = host + (port && !isDefaultPort ? `:${port}` : "");
  const hostAndPort = host + (port && `:${port}`);
  let clipIcon = "📋";
  let joinIcon = "▶️";

  async function copyToClipboard() {
    const backupIcon = clipIcon;
    try {
      await navigator.clipboard.writeText(hostAndPort);
      clipIcon = "✔";
    } catch (error) {
      alert("Dein Browser unterstützt kein Kopieren in die Zwischenablage");
      clipIcon = "❌";
    } finally {
      setTimeout(() => {
        clipIcon = backupIcon;
      }, 1000);
    }
  }

  function join() {
    const backupIcon = joinIcon;
    joinIcon="⚙";
    setTimeout(() => {
        joinIcon = backupIcon;
      }, 1000);
    }
</script>

<p>
  {#if host}
    <span>{label}</span>
    <span
      style="cursor: pointer;"
      on:click={copyToClipboard}
      on:keypress={copyToClipboard}
      title="In die Zwischenablage kopieren">{clipIcon}</span
    >
  {/if}
  {#if joinLink}
    <a href={joinLink} on:click={join} target="_blank" title="Beitreten">{joinIcon}</a>
  {/if}
  <span class="badge {isPrivate ? 'bg-secondary' : 'bg-primary'}"
    >{isPrivate ? "Privat" : "Öffentlich"}</span
  >
</p>
