<script lang="ts">
  import type { Response } from "./types/Minecraft";
  export let host: string;
  export let port: number;
  // Minecraft Server Status
  async function minecraftServerStatus(url) {
    const response = await fetch(url);
    const data = await response.json();
    return data as Response;
  }
  const status = minecraftServerStatus(
    `https://mcapi.us/server/status?ip=${host}&port=${port}`
  );
</script>

{#await status}
  <p class="spinner-border" />
{:then data}
  <p>
    <span
      title={data.online
        ? "Online"
        : "Offline seit " + new Date(parseInt(data.last_online) * 1000)}
      >{data.online ? "✅" : "❎"}</span
    >
    <span
      >{data.players.now}/{data.players.max} Spieler ({data.online
        ? data.server.name
        : "Offline"})</span
    ><br />
    <span style="font-size: 0.7em; color: gray;"
      >Zuletzt aktualisiert: {new Date(
        parseInt(data.last_updated) * 1000
      ).toLocaleString()}</span
    >
  </p>
{:catch error}
  <p class="text-muted">
    Serverstatus konnte nicht abgerufen werden: {error}
  </p>
{/await}
