<script lang="ts">
  import MinecraftStatus from "./MinecraftStatus.svelte";
  import ConnectionInfo from "./ConnectionInfo.svelte";
  import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
  // Do penguins dream?
  let title = "Warum ist da ein Pinguin?";
  let src = "emperor.jpg";
  if (Math.random() > 0.9) {
    title = "Was träumt der Pinguin?";
    src = "emperor_deepdream.jpg";
  }
</script>

<main>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4">
        <h1 class="d-sm-block d-md-none warum">{title}</h1>
        <img {src} alt="Pinguin" class="img-fluid rounded emperor mt-2 mb-2" />
      </div>
      <div class="col-md-8">
        <h1 class="d-none d-md-block warum">{title}</h1>
        <h2>TeamSpeak 3</h2>
        <ConnectionInfo host="gammelban.de" port={9987} isDefaultPort joinLink="ts3server://gammelban.de" />
        <h2>Discord</h2>
        <p>Nur auf Einladung durch ein Mitglied.</p>
        <ConnectionInfo isPrivate/>
        <h2>Minecraft</h2>
        <p>Wir spielen Minecraft Vanilla Survival.</p>
        <ConnectionInfo host="gammelban.de" port={25565} isDefaultPort isPrivate />
        <MinecraftStatus host="gammelban.de" port={25565} />
        <h2>Valheim</h2>
        <ConnectionInfo host="gammelban.de" port={2456} isDefaultPort isPrivate />
      </div>
    </div>
  </div>
</main>
